import React, { useEffect } from "react";

/** Nextjs */
import dynamic from "next/dynamic";

/** UI */
const HomeLayout = dynamic(() => import("../src/layouts/HomeLayout/HomeLayout"));
const Homepage = dynamic(() => import("../src/pages/HomePage/Homepage"));

const HomePageIndex = (props) => {
    
    return (
        <>
            <HomeLayout>
                <Homepage />
            </HomeLayout>
        </>
    );
};

export async function getStaticProps({ locale }) {

    return {
        props: {
        },
        revalidate: 10,
    };
}

export default HomePageIndex;
